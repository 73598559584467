<template>
	<div :id="chartId" class="line-chart" />
</template>
<script>
import { v4 as uuidv4 } from 'uuid'
const echarts = require('echarts/lib/echarts')
require('echarts/lib/chart/line')
require('echarts/lib/component/grid')
require('echarts/lib/component/tooltip')

export default {
	name: 'LineChart',
	props: {
		chartData: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			chartId: `lineChart-${uuidv4()}`,
			options: {
				grid: {
					left: '6px',
					right: '6px',
					top: '20px',
					bottom: '30px',
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					data: [],
					axisTick: { show: false },
					axisLine: { lineStyle: { color: '#EBECF0' } },
					axisLabel: {
						textStyle: { color: '#666' },
						formatter: value => {
							const [year, month] = value.split('-')
							return month == 1 ? `${year}/${month}` : `${month}`
						},
					},
				},
				yAxis: {
					type: 'value',
					splitLine: {
						show: false,
					},
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						lineStyle: {
							opacity: 0,
						},
					},
				},
				series: [
					{
						type: 'line',
						data: [],
						smooth: true,
						showSymbol: false,
						symbolSize: 8,
						emphasis: {
							itemStyle: {
								color: '#fff',
								borderColor: '#4C68F8',
								borderWidth: 3,
							},
						},
						lineStyle: {
							width: 4,
							color: '#4C68F8',
						},
						areaStyle: {
							color: {
								type: 'linear',
								x: 0,
								y: 0,
								x2: 0,
								y2: 1,
								colorStops: [
									{
										offset: 0,
										color: '#B5BDE5',
									},
									{
										offset: 1,
										color: 'rgba(76, 104, 248, 0)',
									},
								],
								global: false,
							},
						},
					},
				],
			},
		}
	},
	watch: {
		chartData: {
			handler() {
				this.updateChart()
			},
			deep: true,
		},
	},
	methods: {
		updateChart() {
			const chartDom = document.getElementById(this.chartId)
			const chart = echarts.init(chartDom)
			this.options.xAxis.data = this.chartData.map(item => item.date)
			this.options.series[0].data = this.chartData.map(item => item.count)
			chart.clear()
			chart.setOption(this.options)
			addEventListener('resize', () => {
				chart.resize()
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.line-chart {
	width: 100%;
	height: 100%;
}
</style>
