<template>
	<div class="card">
		<h2 class="card-title">近7日物联网表通讯上报数</h2>

		<div class="box-bar-chart">
			<bar-chart v-show="chartData.length" ref="mainEl" :chart-data="chartData"></bar-chart>
			<gc-empty v-show="!chartData.length"></gc-empty>
		</div>

		<div class="box-number">
			<p class="text">日均上报数（台）</p>
			<div class="data bold">
				{{ average }}
				<span class="unit">台</span>
			</div>
		</div>
	</div>
</template>

<script>
import BarChart from './BarChart.vue'
import { apiUploadCount } from '@/apis/home.api'

export default {
	name: 'LastCompare',
	components: {
		BarChart,
	},
	data() {
		return {
			average: 0,
			chartData: [],
		}
	},
	methods: {
		getUploadCount() {
			apiUploadCount()
				.then(({ average, list = [] }) => {
					this.average = average
					this.chartData = list.map(item => {
						return {
							date: item.dayOfMonth.replace('-', '/'),
							count: item.num,
						}
					})
				})
				.catch(err => {
					console.error(err)
				})
		},
	},
	activated() {
		this.getUploadCount()
	},
}
</script>
<style scoped lang="scss">
.card {
	height: 268px;
}
.box-bar-chart {
	height: 180px;
	position: absolute;
	bottom: 24px;
	right: 16px;
	left: 128px;
	transform-origin: right;
}
::v-deep {
	.gc-blank-page {
		margin-top: 0;
	}
}

@media screen and (max-width: 1860px) {
	.card {
		width: 100%;
		.box-bar-chart {
			left: 12px;
			right: 12px;
			width: auto;
		}
	}
	.card {
		.box-number {
			position: absolute;
			left: inherit !important;
			bottom: inherit !important;
			top: 12px;
			right: 2px;
		}
	}
}
</style>
