<template>
	<div class="card">
		<h2 class="card-title">物联网表抄表率</h2>
		<p class="subtitle">表型：智能表</p>

		<div class="box-rate-chart pointer" @click="handleOpenDialog">
			<div class="icon">
				<img class="img-rate" src="@/assets/imgs/icon/home-rate.png" />
			</div>
			<circle-chart :chart-data="chartData" ref="mainEl"></circle-chart>
		</div>

		<div class="box-number">
			<p class="text">已抄表（台）</p>
			<div class="data bold">
				{{ count || 0 }}
				<span class="unit">台</span>
			</div>
		</div>

		<meter-rate-dialog :show.sync="showMeterRateDialog"></meter-rate-dialog>
	</div>
</template>

<script>
import CircleChart from './CircleChart.vue'
import MeterRateDialog from './MeterRateDialog/index.vue'
import { apiReadingMeter } from '@/apis/home.api'

export default {
	name: 'MeterRate',
	components: {
		CircleChart,
		MeterRateDialog,
	},
	data() {
		return {
			showMeterRateDialog: false,
			chartData: 0,
			count: 0,
		}
	},
	mounted() {
		this.$nextTick(() => {})
	},
	methods: {
		// 点击在线设备卡片弹出抄表率弹窗
		handleOpenDialog() {
			this.showMeterRateDialog = true
		},
		handleJump() {
			if (!this.$has('monitor:statistical:meter-reading')) {
				this.$message.error('您没有操作权限！')
				return
			}
			this.$router.push({
				name: 'meterRate',
				params: {
					queryCurrentMonth: true,
				},
			})
		},
		getReadingMeter() {
			apiReadingMeter()
				.then(({ meterReadingRate, realMeterReadingCount }) => {
					this.chartData = +meterReadingRate?.replace('%', '')
					this.count = realMeterReadingCount
				})
				.catch(err => {
					console.error(err)
				})
		},
	},
	activated() {
		this.getReadingMeter()
	},
}
</script>
<style scoped lang="scss">
.card {
	height: 226px;
	position: relative;
}
.subtitle {
	font-size: 12px;
	color: #999;
	margin-top: 12px;
}
.box-rate-chart {
	width: 176px;
	height: 176px;
	position: absolute;
	top: 25px;
	right: 8%;
	transform-origin: right;
	.icon {
		position: absolute;
		top: 42%;
		left: 50%;
		transform: translate(-50%, -50%);

		.img-rate {
			width: 37px;
		}
	}
}
</style>
