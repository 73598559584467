<template>
	<ul class="wrapper-item">
		<li
			class="item"
			:style="{ width: item.column ? `${item.column * width}px` : '200px' }"
			v-for="(item, index) in itemList"
			:key="index"
		>
			<p class="label">{{ item.label }}</p>
			<p class="data bold">{{ item.data || 0 }}</p>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'ItemData',
	props: {
		itemList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			width: 200,
		}
	},
}
</script>
<style lang="scss" scoped>
.wrapper-item {
	display: flex;
	flex-wrap: wrap;
	.item {
		margin: 15px 0;
		width: 200px;
		.label {
			color: #9ca0b2;
		}
		.data {
			margin-top: 9px;
		}
	}
}
</style>
