<template>
	<div class="card">
		<ul class="items">
			<li class="item" v-for="item in cardList" :key="item.id">
				<img class="icon" :src="require(`@/assets/imgs/icon/${item.icon}.png`)" />
				<p class="title">{{ item.name }}</p>
				<div class="bold">
					{{ item.data || 0 }}
					<span class="unit">台</span>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import { apiStatisticsCount } from '@/apis/home.api'

export default {
	name: 'DeviceStatis',
	data() {
		return {
			cardList: [
				{
					id: 1,
					name: '物联网表统计',
					key: 'deviceCount',
					icon: 'home-device-total',
				},
				{
					id: 2,
					name: '在线设备',
					key: 'onlineDeviceCount',
					icon: 'home-online-device',
				},
				{
					id: 3,
					name: '告警设备',
					key: 'alarmDeviceCount',
					icon: 'home-alarm',
				},
				{
					id: 4,
					name: '阀门强关设备',
					key: 'closeValveDeviceCount',
					icon: 'home-valve',
				},
				{
					id: 5,
					name: '阀门普关设备',
					key: 'generalCloseValveDeviceCount',
					icon: 'home-valve',
				},
				{
					id: 6,
					name: '开阀设备',
					key: 'openValveDeviceCount',
					icon: 'home-valve',
				},
			],
		}
	},
	methods: {
		getStatisCount() {
			apiStatisticsCount()
				.then(res => {
					this.cardList = this.cardList.map(item => {
						return {
							...item,
							data: res[item.key],
						}
					})
				})
				.catch(err => {
					console.error(err)
				})
		},
		handleJump(item) {
			if (!item.to) {
				this.$emit('open-meter-rate-dialog')
				return
			}
			if (item.permission && !this.$has(item.permission)) {
				this.$message.error('您没有操作权限！')
				return
			}
			this.$router.push({
				name: item.to,
				params: item.params,
			})
		},
	},
	activated() {
		this.getStatisCount()
	},
}
</script>
<style scoped lang="scss">
.card {
	height: 268px;
	display: flex;
}
.items {
	flex: 1;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	.item {
		width: 31%;
		height: 100px;
		background: linear-gradient(225deg, #a7a6ff 0%, #a6beff 100%);
		border-radius: 8px;
		padding: 12px;
		position: relative;
		&:nth-of-type(n + 4) {
			margin-top: 20px;
		}
		.icon {
			width: 28px;
			height: 28px;
		}
		.title {
			margin-top: 8px;
			font-size: 13px;
			color: #fff;
			white-space: nowrap;
		}
		.bold {
			margin-top: 4px;
			color: #fff;
			.unit {
				font-size: 14px;
				font-weight: normal;
			}
		}
		@media (max-width: 1680px) {
			.icon {
				width: 28px;
				height: 28px;
			}
			.title {
				margin-top: 8px;
			}
			.bold {
				margin-top: 8px;
				position: initial;
			}
		}
	}
}
</style>
