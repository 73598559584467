<template>
	<gc-dialog title="抄表率" width="900px" :show.sync="visible" @close="visible = false">
		<div class="row">
			<div class="box-chart">
				<p class="title">总抄表率</p>
				<circle-chart :chart-data="totalMeterRate"></circle-chart>
			</div>
			<item-data class="box-data" :item-list="totalList"></item-data>
		</div>
		<div class="row" v-for="(item, index) in operatorList" :key="index">
			<div class="box-chart">
				<p class="title">{{ item.operator }}抄表率</p>
				<circle-chart
					:linear-gradient="colorList[index]"
					:chart-data="handleRate(item.meterReadingRate)"
				></circle-chart>
			</div>
			<item-data class="box-data" :item-list="handleList(item)"></item-data>
		</div>
		<div slot="footer">
			<!-- <el-button type="primary" plain circle @click="handleJump"
        >前往抄表率报表</el-button
      > -->
			<el-button plain circle @click="visible = false">关 闭</el-button>
		</div>
	</gc-dialog>
</template>

<script>
import CircleChart from '../CircleChart.vue'
import ItemData from './ItemData.vue'
import { apiReadingMeterDetail } from '@/apis/home.api'

export default {
	name: 'MeterRateDialog',
	components: {
		CircleChart,
		ItemData,
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			totalList: [
				{ label: '总档案数(户)', key: 'totalDeviceCount' },
				{ label: '在线总数(户)', key: 'totalOnLineDeviceCount' },
				{ label: '已抄表数(户)', key: 'totalRealMeterReadingCount' },
				{ label: '休眠表数(户)', key: 'totalDormancyMeterReadingCount' },
				{ label: '从未通讯数(户)', key: 'totalNeverCommunicationCount' },
				{ label: '未抄表数(户)', key: 'totalOutMeterReadingCount' },
			],
			totalMeterRate: 0,
			operatorList: [],
			colorList: [
				['#19BE6B', '#36E0A4'],
				['#FFAC05', '#FFD60C'],
				['#986FF0', '#C895FB'],
			],
		}
	},
	computed: {
		visible: {
			get() {
				return this.show
			},
			set(val) {
				this.$emit('update:show', val)
			},
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.getReadingDetai()
			}
		},
	},
	methods: {
		handleJump() {
			if (!this.$has('monitor:statistical:meter-reading')) {
				this.$message.error('您没有操作权限！')
				return
			}
			this.$router.push({
				name: 'meterRate',
				params: {
					queryCurrentMonth: true,
				},
			})
			this.visible = false
		},
		getReadingDetai() {
			apiReadingMeterDetail()
				.then(res => {
					this.totalList = this.totalList.map(item => {
						return {
							...item,
							data: res[item.key],
						}
					})
					this.totalMeterRate = +res.totalMeterReadingRate?.replace('%', '')
					this.operatorList = res.list || []
				})
				.catch(err => {
					console.error(err)
				})
		},
		handleRate(str) {
			return +str?.replace('%', '')
		},
		handleList(item) {
			return [
				{ label: `${item.operator}在线数(户)`, data: item.onLineDeviceCount },
				{ label: '已抄表数(户)', data: item.realMeterReadingCount, column: 2 },
				{ label: '休眠表数(户)', data: item.dormancyMeterReadingCount },
				// { label: "从未通讯数(户)", data: item.neverCommunicationCount },
				{ label: '未抄表数(户)', data: item.outMeterReadingCount },
			]
		},
	},
}
</script>
<style lang="scss" scoped>
.el-button {
	padding: 0 24px;
	height: 32px;
	border-radius: 16px;
}
.row {
	height: 220px;
	border-bottom: 1px dashed #ccc;
	display: flex;
	align-items: center;
	.box-chart {
		flex: 0 0 200px;
		height: 150px;
		position: relative;
		.title {
			position: absolute;
			left: 50%;
			top: 40%;
			transform: translateX(-50%);
			color: #9ca0b3;
		}
	}
	.box-data {
		flex: 1;
		margin-left: 40px;
	}
}
</style>
