<template>
	<div :id="chartId" class="chart-container"></div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid'
const echarts = require('echarts/lib/echarts')
require('echarts/lib/chart/bar')
require('echarts/lib/component/title')
require('echarts/lib/component/polar')

export default {
	name: 'CircleChart',
	props: {
		chartData: {
			type: Number,
			default: 89,
		},
		linearGradient: {
			type: Array,
			default: () => ['#4060FF', '#6B91FF'],
		},
	},
	data() {
		return {
			chartId: `circleChart-${uuidv4()}`,
			options: {
				title: [
					{
						text: '60%',
						x: 'center',
						y: '55%',
						textStyle: {
							fontSize: '24',
							color: this.linearGradient[0],
							fontFamily: 'Impact',
						},
					},
				],
				polar: {
					radius: ['80%', '100%'],
					center: ['50%', '50%'],
				},
				angleAxis: {
					max: 100,
					show: false,
				},
				radiusAxis: {
					type: 'category',
					show: true,
					axisLabel: {
						show: false,
					},
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
				},
				series: [
					{
						name: '',
						type: 'bar',
						roundCap: true,
						barWidth: 60,
						showBackground: true,
						backgroundStyle: {
							color: '#d8dbeb',
						},
						data: [60],
						coordinateSystem: 'polar',
						itemStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
									{
										offset: 0,
										color: this.linearGradient[0],
									},
									{
										offset: 1,
										color: this.linearGradient[1],
									},
								]),
							},
						},
					},
				],
			},
		}
	},
	watch: {
		chartData: {
			handler() {
				this.updateChart()
			},
			deep: true,
		},
	},
	mounted() {
		this.updateChart()
	},
	methods: {
		updateChart() {
			const chartDom = document.getElementById(this.chartId)
			const chart = echarts.init(chartDom)
			const val = this.chartData
			this.options.title[0].text = `${val || 0}%`
			this.options.series[0].data = [val - 1]
			chart.clear()
			chart.setOption(this.options)
			addEventListener('resize', () => {
				chart.resize()
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.chart-container {
	width: 100%;
	height: 100%;
}
</style>
