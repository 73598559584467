<template>
	<div class="box-user card">
		<img class="icon red" src="@/assets/imgs/icon/home-red.png" />
		<img class="icon blue" src="@/assets/imgs/icon/home-blue.png" />
		<img class="icon admin" src="@/assets/imgs/icon/home-admin.png" />
		<div class="wrapper-hello">
			<h2 class="date">{{ dateDay }} {{ dateWeek }}</h2>
			<p class="hello">Hi，{{ userInfo.staffName || '..' }}，今天也要努力工作鸭！</p>
		</div>
		<ul class="items">
			<li class="item" @click="toAccount">
				<img class="iconfont img" src="@/assets/imgs/icon/home-account.png" />
				<div class="item-text">
					<h3>台账管理</h3>
					<p>Equipment Manage</p>
				</div>
			</li>
			<li class="item" @click="toAlarmRecords">
				<i class="iconfont icon-gaojingjilu"></i>
				<div class="item-text">
					<h3>告警记录</h3>
					<p>Alarm Record</p>
				</div>
			</li>
		</ul>

		<!-- 设备新增弹框 -->
		<gc-dialog
			title="设备新增"
			width="900px"
			:show.sync="showAddDialog"
			@ok="handleOk"
			@cancel="handleClose"
			@close="handleClose"
		>
			<device-add
				v-if="showAddDialog"
				ref="addDevice"
				type="add"
				:params.sync="dialogParams"
				@send-dynomic-keys="getDynomicKeys"
			/>
		</gc-dialog>
	</div>
</template>

<script>
import DeviceAdd from '@/pages/device-manage/account/DialogContent.vue'
import { handleParams } from '@/utils'
import { apiAddDevice } from '@/apis/account.api'
import { mapGetters, mapActions } from 'vuex'
import { isBlank } from '@/utils/validate'
import { deviceDetailPermissions } from '@/config/const'

export default {
	name: 'User',
	components: {
		DeviceAdd,
	},
	data() {
		return {
			showAddDialog: false,
			dialogParams: {},
			dateDay: null,
			dateWeek: null,
			weekday: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
			timer: null,
			dynomicKeys: [],
		}
	},
	computed: {
		...mapGetters({
			userInfo: 'userInfo',
		}),
	},
	mounted() {
		this.getCurrentTime()
		this.timer = setInterval(() => {
			this.getCurrentTime()
		}, 1000)
	},
	methods: {
		...mapActions({
			judgeNeedPickOrg: 'user/judgeNeedPickOrg',
		}),
		getCurrentTime() {
			const date = this.dayjs(new Date())
			this.dateDay = date.format('HH:mm:ss')
			this.dateWeek = date.format(this.weekday[date.day()])
		},
		handleAdd() {
			// if (!this.$has("monitor:device:account:maintain")) {
			//   this.$message.error("您没有操作权限！");
			//   return;
			// }
			this.judgeNeedPickOrg().then(flag => {
				if (flag) {
					this.$set(this.dialogParams, 'deviceStatus', 0)
					this.showAddDialog = true
				}
			})
		},
		toAccount() {
			if (!this.$has(deviceDetailPermissions)) {
				this.$message.error('您没有操作权限！')
				return
			}
			this.$router.push('/device/account')
		},
		toAlarmRecords() {
			if (!this.$has('monitor:run:alarm-record:select')) {
				this.$message.error('您没有操作权限！')
				return
			}
			this.$router.push({
				name: 'alarmRecords',
				params: {
					update: true,
				},
			})
		},
		getDynomicKeys(list) {
			this.dynomicKeys = list
		},
		handleOk() {
			let params = {
				dynamicContent: {},
			}
			for (var key in this.dialogParams) {
				let val = handleParams(this.dialogParams)[key]
				if (!isBlank(val)) {
					if (val instanceof Date) {
						if (this.dynomicKeys.includes(key)) {
							params.dynamicContent[key] = this.dayjs(val).format('YYYY-MM-DD')
						} else {
							params[key] = this.dayjs(val).format('YYYY-MM-DD')
						}
					} else if (this.dynomicKeys.includes(key)) {
						params.dynamicContent[key] = val
					} else {
						params[key] = val
					}
				}
			}
			this.$refs?.addDevice?.$refs.contentForm.validate(valid => {
				if (valid && this.$refs.addDevice.validateUseGas()) {
					apiAddDevice(params)
						.then(() => {
							this.$store.dispatch('account/changeStatus', true)
							this.$message.success('设备新增成功！')
							this.showAddDialog = false
							this.dialogParams = {}
						})
						.catch(err => {
							this.$message.error(err?.message || '新增失败')
							this.showAddDialog = false
							this.dialogParams = {}
						})
				}
			})
		},
		handleClose() {
			this.showAddDialog = false
			this.dialogParams = {}
		},
	},
	beforeDestroy() {
		if (this.timer) {
			clearInterval(this.timer)
		}
	},
}
</script>

<style scoped lang="scss">
.box-user.card {
	height: 226px;
	background: linear-gradient(225deg, #a7a6ff 0%, #a6beff 100%);
	color: #fff;
	position: relative;
	&::after {
		content: '';
		width: 92px;
		height: 44px;
		position: absolute;
		top: 0;
		right: -1px;
		background: url('~@/assets/imgs/icon/home-yellow.png') no-repeat;
		background-size: cover;
		background-position: center;
		border-top-right-radius: 16px;
	}
	.icon {
		position: absolute;
		&.red {
			width: 36px;
			top: 24px;
			right: calc(12% + 128px);
		}
		&.blue {
			width: 36px;
			top: 47px;
			right: 5%;
			z-index: 999;
		}
		&.admin {
			width: 120px;
			bottom: 89px;
			right: 12%;
		}
	}
	.wrapper-hello {
		height: 110px;
	}
	.date {
		font-size: 22px;
		font-weight: 500;
		min-height: 22px;
	}
	.hello {
		margin-top: 16px;
		line-height: 21px;
		width: calc(88% - 161px);
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
	.items {
		height: 89px;
		display: flex;
		align-items: center;
		border-top: 1px dashed #fff;
		.item {
			display: flex;
			align-items: center;
			cursor: pointer;
			+ .item {
				margin-left: 20px;
			}
			.iconfont {
				font-size: 36px;
				&.img {
					width: 36px;
				}
			}
			.item-text {
				margin-left: 12px;
				h3 {
					font-weight: 400;
					letter-spacing: 1px;
				}
				p {
					margin-top: 7px;
					font-size: 12px;
					transform: scale(0.8);
					transform-origin: left;
					white-space: nowrap;
				}
			}
		}
	}
}
</style>
